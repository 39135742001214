// Dependencies
import React, { useCallback, useEffect, useState } from 'react';

// Components
import NoResult from '../../Organisms/NoResult/NoResult';
import PageContainer from '../../Containers/PageContainer/PageContainer';
import SurveyDistributionTable from '../../Organisms/SurveyDistributionTable/SurveyDistributionTable';
import SurveyDistributionSummary from '../../Organisms/SurveyDistributionSummary/SurveyDistributionSummary';
import SurveyDistributionLineChart from '../../Organisms/SurveyDistributionLineChart/SurveyDistributionLineChart';
import FilterBar from '../../Organisms/FilterBar/FilterBar';

// Services
import {
  getSurveyCompletesByDistributionChannel,
  SurveyCompletesByDistributionChannel,
} from '../../../services/surveyCompletesByDistributionChannel/getSurveyCompletesByDistributionChannel';

// Models
import {
  handleFilterChange,
  SurveyFilters,
} from '../../../services/getSurveyFilters/SurveyFilters.model';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getSurveyCompletesByDate,
  SurveyCompletesByDate,
} from '../../../services/surveyCompletesByDate/getSurveyCompletesByDate';
import {
  getSurveyCompletesByCountry,
  SurveyCompletesByCountry,
} from '../../../services/surveyCompletesByCountry/getSurveyCompletesByCountry';
import { getCSVExportCompletesByCountryAndSource } from '../../../services/getCSVExportCompletesByCountryAndSource/getCSVExportCompletesByCountryAndSource';
import { getCSVExportCompletesByChannel } from '../../../services/getCSVExportCompletesByChannel/getCSVExportCompletesByChannel';
import { getCSVExportCompletesByDate } from '../../../services/getCSVExportCompletesByDate/getCSVExportCompletesByDate';
import SurveyDistributionVerticalChart from '../../Organisms/SurveyDistributionVerticalChart/SurveyDistributionVerticalChart';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

function SurveyDistribution() {
  const { getAccessTokenSilently } = useAuth0();
  const [
    surveyCompletesByDistributionChannel,
    setSurveyCompletesByDistributionChannel,
  ] = useState<SurveyCompletesByDistributionChannel[]>([]);

  const [surveyCompletesByDate, setSurveyCompletesByDate] = useState<
    SurveyCompletesByDate[]
  >([]);

  const [surveyCompletesByCountry, setSurveyCompletesByCountry] = useState<
    SurveyCompletesByCountry[]
  >([]);

  const [selectedFilters, setFilters] = useState<SurveyFilters>({
    source: undefined,
    age: [],
    country: [],
    tradedProducts: [],
    year: undefined,
  });

  useEffect(() => {
    const makeRequestsAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        getSurveyCompletesByDistributionChannel(selectedFilters, accessToken)
          .then((response) => {
            setSurveyCompletesByDistributionChannel(response);
          })
          .catch(console.error);

        getSurveyCompletesByDate(selectedFilters, accessToken)
          .then((response) => {
            setSurveyCompletesByDate(response);
          })
          .catch(console.error);

        getSurveyCompletesByCountry(
          { ...selectedFilters, year: surveyYear },
          accessToken
        ).then((res) => {
          setSurveyCompletesByCountry(res);
        });
      } catch (e) {
        alert('Authentication Error');
      }
    };
    makeRequestsAsync();
  }, [selectedFilters, getAccessTokenSilently]);

  const downloadSpecificCSV = useCallback(
    async (
      type:
        | 'CompletesByCountryAndSource'
        | 'CompletesByDate'
        | 'CompletesByChannel'
    ) => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        switch (type) {
          case 'CompletesByCountryAndSource': {
            await getCSVExportCompletesByCountryAndSource(
              {
                ...selectedFilters,
                year: surveyYear,
              },
              accessToken
            );
            break;
          }
          case 'CompletesByChannel': {
            await getCSVExportCompletesByChannel(selectedFilters, accessToken);
            break;
          }
          case 'CompletesByDate': {
            await getCSVExportCompletesByDate(selectedFilters, accessToken);
            break;
          }
        }
      } catch (e) {
        alert('Authentication Error');
      }
    },
    [selectedFilters, getAccessTokenSilently]
  );

  return (
    <PageContainer
      bgColor="bg-limestone"
      button={false}
      title="Summary"
      summaryResults={<SurveyDistributionSummary />}
      filterBar={
        <FilterBar onChange={handleFilterChange(setFilters, selectedFilters)} />
      }
    >
      {surveyCompletesByDistributionChannel.length > 0 ? (
        <SurveyDistributionVerticalChart
          className="bg-limestone"
          chartRankButtons={false}
          data={surveyCompletesByDistributionChannel.filter((x) => x.source !== null).map((x) => {
            return {
              answer: x.source,
              complete: x.complete,
              abandoned: x.abandoned,
              year: x.year,
            };
          })}
          verticalChartTitle="Survey completes by distribution channels"
          verticalChartDescription=""
          SVGExportName="SurveyCompletesByDistributionChannel"
          onCSVDownload={() => {
            downloadSpecificCSV('CompletesByChannel');
          }}
        />
      ) : (
        <NoResult variant="Dark" />
      )}

      <SurveyDistributionLineChart
        data={surveyCompletesByDate.map((x) => {
          return {
            date: x.date,
            complete: x.complete,
            abandoned: x.abandoned,
            year: x.year,
          };
        })}
        SVGExportName="CompletesByDate"
        onCSVDownload={() => {
          downloadSpecificCSV('CompletesByDate');
        }}
      />
      <SurveyDistributionTable
        data={surveyCompletesByCountry}
        SVGExportName="CompletesByCountryAndSource"
        onCSVDownload={() => {
          downloadSpecificCSV('CompletesByCountryAndSource');
        }}
      />
    </PageContainer>
  );
}

export default SurveyDistribution;
