// Dependencies
import React, { useState, useEffect, useCallback } from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';
import VerticalChart from '../../Organisms/VerticalChart/VerticalChart';
import HorizontalBarChart from '../../Organisms/HorizontalBarChart/HorizontalBarChart';
import FilterBar from '../../Organisms/FilterBar/FilterBar';
import {
  QuestionResponse,
  getQuestionResponses,
} from '../../../services/questionResponses/getQuestionResponses';
import {
  SurveyCompletesByAge,
  getSurveyCompletesByAge,
} from '../../../services/surveyCompletesByAge/getSurveyCompletesByAge';

// Models
import {
  handleFilterChange,
  SurveyFilters,
} from '../../../services/getSurveyFilters/SurveyFilters.model';
import { RankButtonType } from '../../Organisms/ChartRankButtons/ChartRankButtons.model';
import { useAuth0 } from '@auth0/auth0-react';
import { getCSVResponsesExport } from '../../../services/getCSVResponsesExport/getCSVResponsesExport';
import EtraderChannelsTable from '../../Organisms/EtraderChannelsTable/EtraderChannelsTable';
import { getCSVExportCompletesByAge } from '../../../services/getCSVExportCompletesByAge/getCSVExportCompletesByAge';
import { getCSVExportCompletesByCountry } from '../../../services/getCSVExportCompletesByCountry/getCSVExportCompletesByCountry';
import { Heading } from '../../Atoms/Heading/Heading';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

function SurveyResults() {
  const { getAccessTokenSilently } = useAuth0();
  const [questionResponses, setQuestionResponses] = useState<
    QuestionResponse[]
  >([]);
  const [surveyCompletesByAge, setSurveyCompletesByAge] = useState<
    SurveyCompletesByAge[]
  >([]);
  /*const [surveyCompletesByContry, setSurveyCompletesByContry] = useState<
    SurveyCompletesByCountry[]
  >([]);*/
  /*const [inflationChangePercentage, setInflationChangePercentage] = useState<
    QuestionResponse[]
  >([]);*/

  const [selectedFilters, setFilters] = useState<SurveyFilters>({
    source: [],
    age: [],
    country: [],
    tradedProducts: [],
    year: surveyYear,
  });

  const [
    activeChartButtonGreatestDevelopment,
    setActiveChartButtonGreatestDevelopment,
  ] = useState<RankButtonType>('Rank1');
  const [
    activeChartButtonsTopMarketConcerns,
    setActiveChartButtonsTopMarketConcerns,
  ] = useState<RankButtonType>('Rank1');
  const [
    activeChartButtonsMostInfluentialTechnology,
    setActiveChartButtonsMostInfluentialTechnology,
  ] = useState<RankButtonType>('Rank1');

  const [
    activeChartButtonsElectronicTradingTechnology,
    setActiveChartButtonsElectronicTradingTechnology,
  ] = useState<RankButtonType>('Rank1');

  const getSpecificQuestionResponses = useCallback(
    (questionId: string) => {
      return questionResponses.filter(
        (response) => response.questionId === questionId
      );
    },
    [questionResponses]
  );

  const downloadCSV = useCallback(
    async (questionIds: string[], fileName: string) => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        await getCSVResponsesExport(accessToken, {
          ...selectedFilters,
          questions: questionIds,
        });
      } catch (e) {
        alert('Authentication Error');
      }
    },
    [selectedFilters, getAccessTokenSilently]
  );

  const downloadSpecificCSV = useCallback(
    async (type: 'CompletesByAge' | 'CompletesByCountry') => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        switch (type) {
          case 'CompletesByAge': {
            await getCSVExportCompletesByAge(selectedFilters, accessToken);
            break;
          }
          case 'CompletesByCountry': {
            await getCSVExportCompletesByCountry(selectedFilters, accessToken);
            break;
          }
        }
      } catch (e) {
        alert('Authentication Error');
      }
    },
    [selectedFilters, getAccessTokenSilently]
  );

  useEffect(() => {
    const makeRequestsAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        getQuestionResponses(selectedFilters, accessToken)
          .then((response) => {
            setQuestionResponses(response);
          })
          .catch(console.error);

        getSurveyCompletesByAge(selectedFilters, accessToken)
          .then((response) => setSurveyCompletesByAge(response))
          .catch(console.error);

        /*getSurveyCompletesByCountry(selectedFilters, accessToken)
          .then((response) => setSurveyCompletesByContry(response))
          .catch(console.error);*/
      } catch (e) {
        alert('Authentication Error');
      }
    };
    makeRequestsAsync();
  }, [selectedFilters, getAccessTokenSilently]);

  /*useEffect(() => {
    const inflationChangeResponses = getSpecificQuestionResponses(
      'InflationChangePercentage'
    );

    setInflationChangePercentage(
      inflationChangeResponses.map((entry) => {
        const answer = entry.average || 0;
        return {
          ...entry,
          count:
            entry.answer === 'Inflationwilldecrease'
              ? -Math.abs(answer)
              : Math.abs(answer),
        };
      })
    );
  }, [questionResponses, getSpecificQuestionResponses]);*/

  return (
    <PageContainer
      button={false}
      title="Survey Results"
      summaryResults={<></>}
      filterBar={
        <FilterBar onChange={handleFilterChange(setFilters, selectedFilters)} />
      }
    >
      {/* Q1. What will be your greatest daily trading challenge? */}
      <HorizontalBarChart
        className="bg-limestone"
        results={getSpecificQuestionResponses('GreatestTradingChallenge')}
        verticalChartTitle="What will be your greatest daily trading challenge?"
        verticalChartdescription="Respondents selected only one option."
        SVGExportName="GreatestTradingChallenge"
        onXLSDownload={() => {
          downloadCSV(['GreatestTradingChallenge'], 'GreatestTradingChallenge');
        }}
        footerText=""
      />

      {/* Q2. Which potential developments will have the greatest impact on the markets in 2023? */}
      <VerticalChart
        className="bg-limestone"
        activeChartButton={activeChartButtonGreatestDevelopment}
        setActiveChartButton={setActiveChartButtonGreatestDevelopment}
        chartRankButtons
        data={getSpecificQuestionResponses(
          `GreatestDevelopmentImpact${activeChartButtonGreatestDevelopment}`
        )}
        verticalChartTitle="Which potential developments will have the greatest impact on the markets?"
        verticalChartDescription="Respondents ranked the responses in order of importance. The results below calculate the ranked positon of each option."
        SVGExportName="GreatestDevelopmentImpact"
        onXLSDownload={() => {
          downloadCSV(
            [
              'GreatestDevelopmentImpactRank1',
              'GreatestDevelopmentImpactRank2',
              'GreatestDevelopmentImpactRank3',
            ],
            'GreatestDevelopmentImpact'
          );
        }}
        footerText="*Note that the option 'Geopolitical tension' was an option added for 2025 and the option 'US Election results' was an option in 2024 but removed for 2025"
      />

      {/* Q3. In the next 3 years, which technologies will be most influential for trading? */}
      <VerticalChart
        className="bg-limestone"
        activeChartButton={activeChartButtonsMostInfluentialTechnology}
        setActiveChartButton={setActiveChartButtonsMostInfluentialTechnology}
        chartRankButtons
        data={getSpecificQuestionResponses(
          `MostInfluentialTechnology${activeChartButtonsMostInfluentialTechnology}`
        )}
        verticalChartTitle="In the next 3 years, which technologies will be most influential for trading?"
        verticalChartDescription="Respondents ranked their top 3 responses in order of importance."
        SVGExportName="MostInfluentialTechnology"
        onXLSDownload={() => {
          downloadCSV(
            [
              'MostInfluentialTechnologyRank1',
              'MostInfluentialTechnologyRank2',
              'MostInfluentialTechnologyRank3',
            ],
            'MostInfluentialTechnology'
          );
        }}
        footerText="*Note that the option 'Quantum computing was an option in 2024 but removed for 2025"
      />

      {/* Q4. What are your top 3 market structure concerns? */}
      <VerticalChart
        className="bg-limestone"
        activeChartButton={activeChartButtonsTopMarketConcerns}
        setActiveChartButton={setActiveChartButtonsTopMarketConcerns}
        chartRankButtons
        data={getSpecificQuestionResponses(
          `TopMarketConcerns${activeChartButtonsTopMarketConcerns}`
        )}
        verticalChartTitle="What are your top 3 market structure concerns?"
        verticalChartDescription="Respondents ranked the responses in order of importance. The results below calculate the ranked positon of each option."
        SVGExportName="TopMarketConcerns"
        onXLSDownload={() => {
          downloadCSV(
            [
              'TopMarketConcernsRank1',
              'TopMarketConcernsRank2',
              'TopMarketConcernsRank3',
            ],
            'TopMarketConcerns'
          );
        }}
        footerText="*Note that the option 'Access to emerging and frontier markets' was an option added for 2025"
      />

      {/* Q5a. What is your outlook for the impact of inflation when pricing it in for 2023? */}
      {/*<DoughnutChart
        className="bg-limestone"
        results={getSpecificQuestionResponses('Inflation')}
        doughnutChartTitle="What is your outlook for the impact of inflation when pricing it in for 2023?"
        doughnutChartDescription="Respondents selected only one option."
        onCSVDownload={() => {
          downloadCSV(['Inflation'], 'Inflation');
        }}
      />*/}

      {/* Q5b. What do you think inflation will be by the end of 2023 for the country that you reside in? */}
      {/*<HorizontalBarChart
        className="bg-limestone"
        results={inflationChangePercentage}
        convertToPercentage={false}
        customLegends={[
          'Predictions of respondents that say inflation will increase',
          'Predictions of respondents that say inflation will decrease',
        ]}
        verticalChartTitle="What do you think inflation will be by the end of 2023 for the country that you reside in?"
        verticalChartdescription="Respondents who answered 'Inflation will increase' or 'Inflation will decrease' in the previous question were then asked to decide what it will increase to or decrease to."
        onSVGDownload={() => {
          console.log('trying to export as svg');
        }}
        onCSVDownload={() => {
          downloadCSV(
            ['InflationChangePercentage'],
            'InflationChangePercentage'
          );
        }}
      />*/}

      {/* Q6. What percentage of your trading is and will be through e-Trading channels? */}

      <EtraderChannelsTable
        results={[
          ...getSpecificQuestionResponses(`PercentageETradingThisYear`),
          ...getSpecificQuestionResponses(`PercentageETradingNextYear`),
        ]}
        SVGExportName="PercentageETrading"
        onCSVDownload={() => {
          downloadCSV(
            ['PercentageETradingThisYear', 'PercentageETradingNextYear'],
            'PercentageETrading'
          );
        }}
      />

      {/* What type of platform do you primarily use for your institutional trading activities? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('InstitutionalTradingPlatform')}
        verticalChartTitle="What type of platform do you primarily use for your institutional trading activities?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="InstitutionalTradingPlatform"
        onXLSDownload={() => {
          downloadCSV(
            ['InstitutionalTradingPlatform'],
            'InstitutionalTradingPlatform'
          );
        }}
        footerText="*Note that this is a new question for 2025"
      />

      {/* What is the most important criteria when selecting a single dealer platform? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('SingleDealerPlatform')}
        verticalChartTitle="What is the most important criteria when selecting a single dealer platform?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="SingleDealerPlatform"
        onXLSDownload={() => {
          downloadCSV(
            ['SingleDealerPlatform'],
            'SingleDealerPlatform'
          );
        }}
        footerText="*Note that this is a new question for 2025 and only respondents that answered 'Single-dealer platform' or 'Both' in the previous question saw this one"
      /> 
      {
        // Apart from pricing and execution, which features/capabilities are most valuable to you on a trading platform?
      }
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('FavouriteFeatures')}
        verticalChartTitle="Apart from pricing and execution, which features/capabilities are most valuable to you on a trading platform?"
        verticalChartDescription="Respondents selected up to 3 options."
        bottomTitle=""
        SVGExportName="FavouriteFeatures"
        onCSVDownload={() => {
          downloadCSV(
            ['FavouriteFeatures0', 'FavouriteFeatures1', 'FavouriteFeatures2'],
            'FavouriteFeatures'
          );
        }}
        footerText="*Note that this is a new question for 2024"
      />
      {
        // Which data and analytics tools are most valuable to you?
      }
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('DataAndAnalyticsTools')}
        verticalChartTitle="Which data and analytics tools are most valuable to you?"
        verticalChartDescription="Respondents selected up to 3 options."
        bottomTitle=""
        SVGExportName="DataAndAnalyticsTools"
        onCSVDownload={() => {
          downloadCSV(
            ['DataAndAnalyticsTools'],
            'DataAndAnalyticsTools'
          );
        }}
        footerText="*Note that this is a new question for 2025"
      />

      {/* Q10. Which of these products do you think will have the most developments in electronic trading in the next 12 months? */}
      <VerticalChart
        className="bg-limestone"
        activeChartButton={activeChartButtonsElectronicTradingTechnology}
        setActiveChartButton={setActiveChartButtonsElectronicTradingTechnology}
        chartRankButtons
        data={getSpecificQuestionResponses(
          `ProductDevelopment${activeChartButtonsElectronicTradingTechnology}`
        )}
        verticalChartTitle="Which of these products do you think will have the most developments in electronic trading in the next 12 months?"
        verticalChartDescription="Respondents ranked the responses in order of importance. The results below calculate the ranked positon of each option."
        SVGExportName="ProductDevelopment"
        onXLSDownload={() => {
          downloadCSV(
            [
              'ProductDevelopmentRank1',
              'ProductDevelopmentRank2',
              'ProductDevelopmentRank3',
            ],
            'ProductDevelopment'
          );
        }}
        footerText=""
      />

      {/* Q7a. Which option best describes your institutional work with crypto/digital coins? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('CryptoTradingStatus')}
        verticalChartTitle="Which option best describes your institutional work with crypto/digital coins?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="CryptoTradingStatus"
        onXLSDownload={() => {
          downloadCSV(['CryptoTradingStatus'], 'CryptoTradingStatus');
        }}
        footerText=""
      />

      {/* Q7b. How will your institutional trading with crypto/digital coins change over 2023? */}
      {/*
      <DoughnutChart
        className="bg-limestone"
        results={getSpecificQuestionResponses('CryptoTradingChange')}
        doughnutChartTitle="How will your institutional trading with crypto/digital coins change over 2023?"
        doughnutChartDescription="Respondents selected only one option."
        onCSVDownload={() => {
          downloadCSV(['CryptoTradingChange'], 'CryptoTradingChange');
        }}
      />
      */}

      <div className={`p-4 lg:px-12 lg:pt-8`}>
        <Heading level="h2" className="text-brown-500">
          Demographic questions
        </Heading>
      </div>
      {/* Q8. Which products do you currently institutionally trade? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('CurrentTradedProducts')}
        verticalChartTitle="Which products do you trade at work/ for your entity?"
        verticalChartDescription="Respondents selected all options that applied."
        bottomTitle=""
        SVGExportName="CurrentTradedProducts"
        onXLSDownload={() => {
          downloadCSV(['CurrentTradedProducts'], 'CurrentTradedProducts');
        }}
      />

      {/* Q9. How long have you been an institutional trader? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('TraderServiceLength')}
        verticalChartTitle="How long have you been an institutional trader?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="TraderServiceLength"
        onXLSDownload={() => {
          downloadCSV(['TraderServiceLength'], 'TraderServiceLength');
        }}
      />

      {/* Q11. Which option best describes your age? */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={surveyCompletesByAge.map((item) => {
          return { answer: item.age, count: item.count, year: item.year };
        })}
        verticalChartTitle="How old are you?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="CompletesByAge"
        onCSVDownload={() => {
          downloadSpecificCSV('CompletesByAge');
        }}
      />

      {/* Q12. Which country do you reside in? */}
      {/*<VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        verticalChartTitle="Which country do you currently reside in?"
        verticalChartDescription="Respondents selected all options that applied."
        bottomTitle="Regions"
        data={surveyCompletesByContry.map((item) => {
          return {
            answer: item.country,
            count: item.sources.reduce((prev, current) => {
              return prev + current.count;
            }, 0),
            year: 2023,
          };
        })}
        onCSVDownload={() => {
          downloadSpecificCSV('CompletesByCountry');
        }}
      />*/}
       {/* Q7a. which benefit of direct connectivity do you feel is most valuuable. 2024 data only */}
      <VerticalChart
        className="bg-limestone"
        chartRankButtons={false}
        data={getSpecificQuestionResponses('DirectConnectivityBenefit')}
        verticalChartTitle="Which benefit of direct connectivity do you feel is most valuable?"
        verticalChartDescription="Respondents selected only one option."
        bottomTitle=""
        SVGExportName="DirectConnectivityBenefit"
        onXLSDownload={() => {
          downloadCSV(
            ['DirectConnectivityBenefit'],
            'DirectConnectivityBenefit'
          );
        }}
        footerText="*Note that this question was removed for 2025"
        prevYearFilterEnabled={true}
      />
    </PageContainer>
  );
}

export default SurveyResults;
