import { triggerFileDownload } from '../../helpers/triggerFileDownload/triggerFileDownload';
import { api } from '../axiosInstance';

export interface CSVExportParams {
  year: number | undefined;
}

export const getCSVExport = async (params: CSVExportParams, token: string) => {
  new Promise((resolve, reject) => {
    // Make the request
    api
      .get<File>('CSVExport', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // Set Prefix
        const currentDate = new Date();
        const datePrefix = `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()}`;

        // Download and resolve.
        triggerFileDownload(res, `SurveyExport_${params.year}_${datePrefix}`);
        resolve(res.data);
      })
      .catch((error) => {
        // Alert and reject.
        alert('Failed to download CSV export, please try again later.');
        reject(error);
      });
  });
};
