// function to accept string array and iterate that array to return a new array with each element split into chunks
export function formatLabels(items: string[]): string[] {
  let answer = [];
  for (let i = 0; i < items.length; i++) {
      // Split the current item on spaces
      let array = items[i].split(' ');
      let thisString = "";
      let thisSpace = "";

      for (let j = 0; j < array.length; j++) {
          let word = array[j];

          // Check if the word contains a '/'
          if (word.includes('/')) {
              // Split at '/' and keep the '/' character
              let parts = word.split('/');
              if (parts[1] !== '') {
                word = parts[0] + '/\n' + parts[1]; // Add line break after '/'
              }
              else {
                word = parts[0] + '/'; // Keep '/' at the end of the word
              }
          }

          // Add the word to the resulting string with proper spacing and newlines
          if (j % 2 === 0) {
              thisString = thisString + thisSpace + word + '\n';
          } else {
              thisString = thisString + thisSpace + word;
          }
          thisSpace = " ";
      }

      answer.push(thisString);
  }

  return answer;
}