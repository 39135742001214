import React, { useEffect, useRef, useState } from 'react';
import { exportChartToSvg } from '../../../helpers/triggerFileDownload/triggerFileDownload';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import NoResult from '../NoResult/NoResult';
import { EtraderChannelsTableProps } from './EtraderChannelsTable.model';

const surveyYear: number = Number.parseInt(process.env.REACT_APP_SURVEY_YEAR!);

function EtraderChannelsTable({
  results,
  SVGExportName,
  onCSVDownload,
}: EtraderChannelsTableProps) {
  const [platforms, setPlatforms] = useState<string[]>([]);
  const chartRef = useRef<null | HTMLTableElement>(null);

  useEffect(() => {
    setPlatforms(Array.from(new Set(results.map((result) => result.answer))));
  }, [results]);

  const exportCsv = () => {
    var csv = [];

    const headerRow = [
      'Products Traded',
      `${surveyYear - 1} (Actual)`,
      `${surveyYear} (Actual)`,
      `${surveyYear + 1} (Predicted)`,
      `% Increase (${surveyYear} Actual-${surveyYear + 1} Predicted)`,
    ];

    csv.push(headerRow.join(','));

    for (const platform of platforms) {
      // PreviousYear actual results given in PreviousYear
      const yearPreviousYear = results.find(
        (result) =>
          result.answer === platform &&
          result.year === surveyYear - 1 &&
          result.questionId === 'PercentageETradingThisYear'
      );
      // This year actual results given in this year
      const yearThisYear = results.find(
        (result) =>
          result.answer === platform &&
          result.year === surveyYear &&
          result.questionId === 'PercentageETradingThisYear'
      );
      // Next year predicted results given in this year
      const yearNextYear = results.find(
        (result) =>
          result.answer === platform &&
          result.year === surveyYear &&
          result.questionId === 'PercentageETradingNextYear'
      );

      const row = [
        platform,
        yearPreviousYear ? Math.round(yearPreviousYear.average!) + '%' : 'N/A',
        yearThisYear ? Math.round(yearThisYear.average!) + '%' : 'N/A',
        yearNextYear ? Math.round(yearNextYear.average!) + '%' : 'N/A',
        yearThisYear && yearNextYear
          ? Math.round(yearNextYear.average!) -
            Math.round(yearThisYear.average!) +
            '%'
          : 'N/A',
      ];

      csv.push(row.join(','));
    }

    var csvFile;
    var downloadLink;

    if (
      window.Blob === undefined ||
      window.URL === undefined ||
      window.URL.createObjectURL === undefined
    ) {
      alert("Your browser doesn't support Blobs");
      return;
    }

    const currentDate = new Date();
    const datePrefix = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

    const filename = `Survey_Summary_YoY-${datePrefix}`;

    csvFile = new Blob([csv.join('\n')], { type: 'text/csv' });
    downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <div className="w-full overflow-hidden bg-limestone p-4 lg:px-12 lg:py-8">
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full md:w-[50%] lg:w-[100%] xl:w-[60%]">
          <Heading level="h4" className="mb-2 xl:mb-4">
            What percentage of your trading is and will be through e-Trading
            channels? This includes API, multi-dealer platforms and
            single-dealer platforms.
          </Heading>
          <Paragraph variant="sm" className="mb-6 lg:mb-8">
            Respondents selected a percentage for each response.
          </Paragraph>
        </div>
        <div className="flex w-80 gap-x-4 lg:w-80">
          {SVGExportName && (
            <Button
              variant="secondary"
              className="mb-6 w-1/2 lg:w-auto xl:mb-0"
              type="button"
              onClick={() => {
                exportChartToSvg(SVGExportName || 'chart', chartRef);
              }}
            >
              Download SVG
            </Button>
          )}
          <Button
            variant="standard"
            className="mb-6 w-1/2 lg:w-auto xl:mb-0"
            type="button"
            onClick={exportCsv}
          >
            Download CSV
          </Button>
        </div>
      </div>
      {results.length > 0 ? (
        <div className="mt-4 w-full max-w-full overflow-hidden xl:mt-0">
          <div className="relative w-auto max-w-full overflow-auto">
            <table
              ref={chartRef}
              className="w-full table-auto text-center font-normal text-gray-500"
            >
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  <th scope="col" className="bg-limestone py-6 px-6"></th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    {surveyYear - 1} (Actual)
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    {surveyYear} (Actual)
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    {surveyYear + 1} (Predicted)
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    % increase
                    <br />({surveyYear}-{surveyYear + 1})
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-titanium">
                {
                  //TODO: Finish this chart
                }
                {platforms.map((platform, platformIndex) => {
                  // PreviousYear actual results given in PreviousYear
                  const yearPreviousYear = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === surveyYear - 1 &&
                      result.questionId === 'PercentageETradingThisYear'
                  );
                  // ThisYear actual results given in ThisYear
                  const yearThisYear = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === surveyYear &&
                      result.questionId === 'PercentageETradingThisYear'
                  );
                  // NextYear predicted results given in ThisYear
                  const yearNextYear = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === surveyYear &&
                      result.questionId === 'PercentageETradingNextYear'
                  );

                  return (
                    <tr className="border-b bg-white" key={platformIndex}>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {platform}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {yearPreviousYear
                          ? Math.round(yearPreviousYear.average!) + '%'
                          : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {yearThisYear
                          ? Math.round(yearThisYear.average!) + '%'
                          : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {yearNextYear
                          ? Math.round(yearNextYear.average!) + '%'
                          : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {yearThisYear && yearNextYear
                          ? Math.round(yearNextYear.average!) -
                            Math.round(yearThisYear.average!) +
                            '%'
                          : 'N/A'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
}

export default EtraderChannelsTable;
